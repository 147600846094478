import { put, call, takeLatest, select, takeEvery } from 'redux-saga/effects';
import types from './types';
import actions from './actions';
import { GetMatches } from '../services/webAppEndPoint';
import { profileSelectors } from '../profile';
import { conversationsTypes } from '../conversations';
import { swipesTypes } from '../swipes';

function* getMatches() {
  try {
    const { matches, moreRecords, nextCursor } = yield call(GetMatches);

    yield put(actions.getMatchesSuccess(matches, moreRecords, nextCursor));
  } catch (error) {
    yield put(actions.getMatchesFailure(error));
  }
}

function* handleGetMatches() {
  yield takeLatest(types.getMatches, getMatches);
}

function* getMoreMatches({ nextCursor }) {
  try {
    const { matches, moreRecords, nextCursor: nextCursorr } = yield call(GetMatches, nextCursor);

    yield put(actions.getMoreMatchesSuccess(matches, moreRecords, nextCursorr));
  } catch (error) {
    yield put(actions.getMoreMatchesFailure(error));
  }
}

function* handleGetMoreMatches() {
  yield takeLatest(types.getMoreMatches, getMoreMatches);
}

function* addMatch({ conversation }) {
  try {
    const myId = yield select(profileSelectors.getProfileId);
    const { id: conversationId, users, ...item } = conversation;
    const match = { ...item, ...users.find(({ id }) => id !== myId), conversationId };
    yield put(actions.addMatch(match));
  } catch (error) {
    yield put(actions.addMatchFailure(error));
  }
}

function* handleAddMatch() {
  yield takeEvery([conversationsTypes.addConversation, swipesTypes.matched], addMatch);
}

export default { handleGetMatches, handleGetMoreMatches, handleAddMatch };
