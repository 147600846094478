const getMatches = 'app/matches/GET';
const getMatchesSuccess = 'app/matches/GETSUCCESS';
const getMatchesFailure = 'app/matches/GETFAILURE';
const getMoreMatches = 'app/matches/GETMORE';
const getMoreMatchesSuccess = 'app/matches/GETMORESUCCESS';
const getMoreMatchesFailure = 'app/matches/GETMOREFAILURE';
const addMatch = "app/matches/ADD";
const addMatchFailure = "app/matches/ADDFAILURE";

export default {
  getMatches,
  getMatchesSuccess,
  getMatchesFailure,
  getMoreMatches,
  getMoreMatchesSuccess,
  getMoreMatchesFailure,
  addMatch,
  addMatchFailure
};
