import { createSelector, createStructuredSelector } from 'reselect';
import { reverse, sortBy } from 'lodash';

const getMatchesPart = state => state.matches;
const getMatchesById = state => getMatchesPart(state).byId;
const getAllMatchIds = state => getMatchesPart(state).allIds;
const getIsLoading = state => getMatchesPart(state).isLoading;
const getMoreRecords = state => getMatchesPart(state).moreRecords;
const getNextPage = state => getMatchesPart(state).nextPage;

const getSortedMatches = createSelector(
  getAllMatchIds,
  getMatchesById,
  (ids, matchItems) => reverse(
    sortBy(
      ids.reduce(
        (arr, item) => (matchItems[item].createdAt ? [...arr, matchItems[item]] : arr),
        [],
      ),
      'createdAt',
    )
  ),
);

const getMatches = createStructuredSelector({
  matches: getSortedMatches,
  isLoading: getIsLoading,
  moreRecords: getMoreRecords,
  nextPage: getNextPage,
});

export default { getMatches };
