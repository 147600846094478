import { combineReducers } from 'redux';
import { uniq, keyBy } from 'lodash';
import types from './types';
import { friendsTypes } from '../friends';
import { friendShipStatus } from '../enums';

const allIdsReducer = (state = [], action) => {
  switch (action.type) {
    case types.getMatchesSuccess:
      return action.matches.map(({ id }) => id);
    case types.getMoreMatchesSuccess:
      return uniq([...state, ...action.matches.map(({ id }) => id)]);
    case types.addMatch: {
      if (action.match.id) {
        return uniq([...state, action.match.id]);
      }
      return state;
    }
    default:
      return state;
  }
};

const byIdReducer = (state = {}, action) => {
  switch (action.type) {
    case types.getMatchesSuccess:
      return keyBy(action.matches, 'id');
    case types.getMoreMatchesSuccess:
      return { ...state, ...keyBy(action.matches, 'id') };
    case types.addMatch:
      return { ...state, [action.match.id]: action.match };
    case friendsTypes.sendFriendRequestSuccess:
    case friendsTypes.removeFriendSuccess:
    case friendsTypes.retreatFriendRequestSuccess:
    case friendsTypes.addFriendSuccess:
    case friendsTypes.rejectFriendSuccess: {
      if (state[action.id]) {
        return { ...state, [action.id]: userReducer(state[action.id], action) };
      }
      return state;
    }
    default:
      return state;
  }
};

const userReducer = (state, action) => {
  const { type } = action;
  switch (type) {
    case friendsTypes.sendFriendRequestSuccess:
      return {
        ...state,
        friendrequeststatus: friendShipStatus.pending,
        friendshipCounter: state.friendshipCounter ? state.friendshipCounter + 1 : 1,
      };
    case friendsTypes.rejectFriendSuccess:
      return { ...state, friendrequeststatus: friendShipStatus.denied };
    case friendsTypes.removeFriendSuccess:
      return { ...state, friendrequeststatus: friendShipStatus.noRequest };
    case friendsTypes.retreatFriendRequestSuccess:
      return { ...state, friendrequeststatus: friendShipStatus.noRequest };
    case friendsTypes.addFriendSuccess:
      return { ...state, friendrequeststatus: friendShipStatus.accepted };
    default:
      return state;
  }
};

const moreRecordsReducer = (state = true, action) => {
  const { type, moreRecords } = action;
  switch (type) {
    case types.getMoreMatchesSuccess:
    case types.getMatchesSuccess:
      return moreRecords;
    default:
      return state;
  }
};

const nextPageReducer = (state = null, action) => {
  switch (action.type) {
    case types.getMatchesSuccess: {
      const { nextCursor } = action;
      return nextCursor;
    }
    case types.getMoreMatchesSuccess: {
      const { nextCursor } = action;
      return nextCursor;
    }
    default:
      return state;
  }
};

const loadingReducer = (state = false, action) => {
  switch (action.type) {
    case types.getMatches:
    case types.getMoreMatches:
      return true;
    case types.getMatchesFailure:
    case types.getMatchesSuccess:
    case types.getMoreMatchesFailure:
    case types.getMoreMatchesSuccess:
      return false;
    default:
      return state;
  }
};

export default combineReducers({
  allIds: allIdsReducer,
  byId: byIdReducer,
  moreRecords: moreRecordsReducer,
  nextPage: nextPageReducer,
  isLoading: loadingReducer,
});
