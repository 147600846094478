import types from './types';

const getMatches = () => ({ type: types.getMatches });
const getMatchesSuccess = (matches, moreRecords, nextCursor) => ({
  type: types.getMatchesSuccess,
  matches,
  moreRecords,
  nextCursor,
});
const getMatchesFailure = (error) => ({ type: types.getMatchesFailure, error });
const getMoreMatches = (nextCursor) => ({ type: types.getMoreMatches, nextCursor });
const getMoreMatchesSuccess = (matches, moreRecords, nextCursor) => ({
  type: types.getMoreMatchesSuccess,
  matches,
  moreRecords,
  nextCursor,
});
const getMoreMatchesFailure = (error) => ({
  type: types.getMoreMatchesFailure,
  error,
});
const addMatch = (match) => ({ type: types.addMatch, match });
const addMatchFailure = (error) => ({ type: types.addMatchFailure, error });

export default {
  getMatches,
  getMatchesSuccess,
  getMatchesFailure,
  getMoreMatches,
  getMoreMatchesSuccess,
  getMoreMatchesFailure,
  addMatch,
  addMatchFailure,
};
